// @flow
// basic
import messages from './ProductsPage.lang'
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { useIntl } from 'gatsby-plugin-intl'
import ScrollAnimation from 'react-animate-on-scroll'
// components
import IntroTeaser from '../IntroTeaser'
import { Container } from '../../components/basic/Grid'
import SubpageHeroTeaser from '../../components/SubpageHeroTeaser'
import ClientOverview from '../Clients/ClientOverview'
// assets
/* eslint-disable max-len */
const imageDesktop = (
  <StaticImage src="../../images/products/teaser-overview-bg-desktop.jpg" alt="" objectFit="cover" className="image" />
)
const teaser1Desktop = (
  <StaticImage
    src="../../images/products/product-overview-teaser1-desktop.jpg"
    alt=""
    objectFit="cover"
    className="image"
  />
)
const tsboOverviewDesktop = (
  <StaticImage src="../../images/products/tsbo-overview-desktop.png" alt="" objectFit="contain" className="image" />
)
const mightycOverviewDesktop = (
  <StaticImage src="../../images/products/mightyc-overview-desktop.png" alt="" objectFit="contain" className="image" />
)
const freshmsOverviewDesktop = (
  <StaticImage src="../../images/products/freshms-overview-desktop.png" alt="" objectFit="contain" className="image" />
)
const fusionOverviewDesktop = (
  <StaticImage
    src="../../images/products/fusion/fusion-overview-desktop.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const quickstartOverviewDesktop = (
  <StaticImage
    src="../../images/products/quickstart/quickstart-overview-desktop.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)

import logoFreshmsSrc from '../../images/logo/freshms-color.svg'
const logoFreshms = <img src={logoFreshmsSrc} alt="" className="image" />
import logoMightycSrc from '../../images/logo/mightyc-color.svg'
const logoMightyc = <img src={logoMightycSrc} alt="" className="image" />
import logoTsboSrc from '../../images/logo/travelsandbox-color.svg'
const logoTsbo = <img src={logoTsboSrc} alt="" className="image" />
import logoQuickstartSrc from '../../images/logo/quickstart-color.svg'
const logoQuickstart = <img src={logoQuickstartSrc} alt="" className="image" />
import logoFusionSrc from '../../images/logo/fusion-color.svg'
const logoFusion = <img src={logoFusionSrc} alt="" className="image" />
/* eslint-enable max-len */

import baseStyles from './ProductsPage.scss'

type Props = {
  ...StyleProps
}

const ProductsPage = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <SubpageHeroTeaser title={formatMessage(messages.title)} image={imageDesktop} imgAlt="office gallery" />
      <Container fluid>
        <div className={styles.teaserWrap}>
          <ScrollAnimation animateIn="fadeInRight" animateOnce delay={150}>
            <IntroTeaser
              title={formatMessage(messages.teaserTitle)}
              copy={
                <>
                  <p>{formatMessage(messages.teaserText1)}</p>
                  <p>{formatMessage(messages.teaserText2)}</p>
                </>
              }
              image={teaser1Desktop}
              styles={styles.intro}
            />
          </ScrollAnimation>
          <ScrollAnimation className={styles.tsboWrapper} animateIn="fadeInLeft" animateOnce delay={150}>
            <IntroTeaser
              styles={styles.promo}
              copy={
                <>
                  <div className={styles.promoLogo}>{logoQuickstart}</div>
                  <p>{formatMessage(messages.quickstartCopy)}</p>
                </>
              }
              image={quickstartOverviewDesktop}
              btnLink="/products/quickstart/"
              btn={formatMessage(messages.quickstartBtn)}
              fullWidth
            />
          </ScrollAnimation>
          <ScrollAnimation className={styles.mightycWrapper} animateIn="fadeInLeft" animateOnce delay={150}>
            <IntroTeaser
              styles={styles.promo}
              copy={
                <>
                  <div className={styles.promoLogo}>{logoFusion}</div>
                  <p>{formatMessage(messages.fusionCopy)}</p>
                </>
              }
              image={fusionOverviewDesktop}
              btnLink="/products/fusion/"
              btn={formatMessage(messages.fusionBtn)}
              fullWidth
              reversed
              simple
            />
          </ScrollAnimation>
          <ScrollAnimation className={styles.tsboWrapper} animateIn="fadeInRight" animateOnce delay={150}>
            <IntroTeaser
              styles={styles.promo}
              copy={
                <>
                  <div className={styles.promoLogo}>{logoTsbo}</div>
                  <p>{formatMessage(messages.tsboCopy)}</p>
                </>
              }
              image={tsboOverviewDesktop}
              btnLink="/products/travelsandbox/"
              btn={formatMessage(messages.tsboBtn)}
              fullWidth
            />
          </ScrollAnimation>
          <ScrollAnimation className={styles.mightycWrapper} animateIn="fadeInLeft" animateOnce delay={150}>
            <IntroTeaser
              styles={styles.promo}
              copy={
                <>
                  <div className={styles.promoLogo}>{logoFreshms}</div>
                  <p>{formatMessage(messages.freshmsCopy)}</p>
                </>
              }
              image={freshmsOverviewDesktop}
              btnLink="/products/freshms/"
              btn={formatMessage(messages.freshmsBtn)}
              fullWidth
              reversed
              simple
            />
          </ScrollAnimation>
          <ScrollAnimation className={styles.tsboWrapper} animateIn="fadeInRight" animateOnce delay={150}>
            <IntroTeaser
              styles={styles.promo}
              copy={
                <>
                  <div className={styles.promoLogo}>{logoMightyc}</div>
                  <p>{formatMessage(messages.mightycCopy)}</p>
                </>
              }
              image={mightycOverviewDesktop}
              btnLink="/products/mightyc/"
              btn={formatMessage(messages.mightycBtn)}
              fullWidth
            />
          </ScrollAnimation>
        </div>
        <ScrollAnimation className={styles.tsboWrapper} animateIn="fadeInLeft" animateOnce delay={150}>
          <ClientOverview />
        </ScrollAnimation>
      </Container>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ProductsPage)
