/* eslint-disable max-len */

import { defineMessages } from 'react-intl'

export default defineMessages({
  title: 'Products',
  teaserTitle: 'Professional software, designed for humans',
  teaserText1: '«The computer industry has never been near a designer, in its entirety.» – Luigi Colani. That"s right, we want to change that. We want your software to be geared to your business and to your customers. Long enough you had to follow the data sources. That"s why develop software using the systems engineering approach since more than ten years.',
  teaserText2: 'This means: Frontends are designed according to the needs of the users. The middleware supports the frontends and enables communication with data sources and processing systems. Not vice versa.',
  tsboCopy: 'Rely on years of experience that cumulates the TravelSandbox®. The TravelSandbox® compiles in realtime dynamic content objects for all your frontends. Your benefit: e-commerce always aligned with your merchandising management and accounting systems.',
  tsboBtn: 'TravelSandbox® - Framework',
  mightycCopy: 'mightyC® is a content player that offers a collection of features, tools and APIs that manages your content. It helps to aggregate your content to make it reusable, and easy to distribute.',
  mightycBtn: 'mightyC® - Content Aggregator',
  freshmsCopy: 'Marketing is an integral success factor in e-commerce. We have understood this and therefore developed freshMS. Directly in the frontend you create pages for marketing and SEO, control teasers and optimize your landing pages. Without delay and without programming knowledge.',
  freshmsBtn: 'freshMS® - Content Management',
  fusionCopy: 'Shops and booking platforms live from the selection and attractiveness of the products offered. Fusion enables you to enrich your portfolio and optimize it for your target markets. Fusion manages all your product sources and gives you broad possibilities to optimize your portfolio.',
  fusionBtn: 'Fusion - Product Sources Connector',
  quickstartCopy: 'Quickstart is a ready-to-use Internet Booking Engine, to which you can quickly connect your offer sources and booking systems to generate revenue. Since all features of the TravelSandbox® are preconfigured and available in Quickstart, you can concentrate on the frontend, the selection of data sources and booking systems. More focus, shorter lead times, less costs and dependencies.',
  quickstartBtn: 'Quickstart - Internet Booking Engine'
})
